<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="text-center">
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/logo-groupe-emc.svg"
                  alt
                  height="150"
                  class="logo logo-dark m-auto"
                />
                <img
                  src="@/assets/images/logo-groupe-emc.svg"
                  alt
                  height="150"
                  class="logo logo-light m-auto"
                />
              </router-link>
              <div class="card">
                <div class="card-body p-4">
                  <div class="text-center mt-2">
                    <h5 class="text-primary">Nouveau mot de passe</h5>
                  </div>
                  <div class="p-2 mt-4">
                    <div
                      class="alert alert-success text-center mb-4"
                      role="alert"
                    >
                      Choisissez un mot de passe pour votre compte
                    </div>
                    <form @submit.prevent="tryToReset">
                      <div class="mb-3">
                        <label for="password">Mot de passe</label>
                        <input
                            type="password"
                            v-model="password"
                            class="form-control"
                            id="password"
                            placeholder="Entrer un mot de passe"
                            :class="{
                            'is-invalid': submitted && $v.password.$error,
                            }"
                        />
                        <div
                            v-if="submitted && !$v.password.required"
                            class="invalid-feedback"
                        >
                            Veuillez entrer votre mot de passe.
                        </div>
                      </div>
                      <div class="row mb-0">
                        <div class="col-12 text-end">
                          <button class="btn btn-primary w-sm" type="submit">
                            Envoyer
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="mt-5 text-center">
                <p>
                  © {{ new Date().getFullYear() }} Groupe EMC
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../api/api';
import appConfig from "@/app.config";
import Utilities from "../../../services/utilities";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

/**
 * Forgot Password component
 */
export default {
  page: {
    title: "Nouveau mot de passe",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      password: "",
      submitted: false,
      error: null,
      title: "Nouveau mot de passe",
    }
  },
  validations: {
      password: {
        required
      }
  },
  mounted() {
    if (!this.$route.query.token) {
        this.$router.push('/login');
    }
  },
  methods: {
    tryToReset() {
        this.submitted = true;
        this.$v.$touch();

        if (this.$v.$invalid) {
            return;
        } else {
            var token = this.$route.query.token;

            if (!token || token == '') {
                Utilities.errormsg();
                return;
            }

            if (!this.password || this.password == '') {
                this.error = true;
                return;
            }

            api
                .post('/account/reset-password', {
                    'token': token,
                    'password': this.password
                })
                .then(
                    (response) => {
                        if (response && response.status == 200) {
                            this.submitted = true;
                            this.error = false;
                            Swal.fire({
                                title: "Succès",
                                text: response.data,
                                icon: "success",
                                confirmButtonColor: "#2e58a6",
                                confirmButtonText: "Se connecter",
                            }).then(
                                (result) => {
                                    if (result.isConfirmed) {
                                        this.$router.push('/login');
                                    }
                                }
                            );
                        }
                    },
                    (error) => {
                        Utilities.alertmsg(error.response.data.detail);
                        this.submitted = true;
                    }
                )
        } 
    }
  }
};
</script>

<style lang="scss" module></style>
